html, body {
    min-height: 100%;
    padding: 0;
    margin: 0;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    color: #666;
    }
    h1 {
    margin: 0 0 20px;
    font-weight: 400;
    color: #1c87c9;
    }
    p {
    margin: 0 0 5px;
    }
    .main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('https://vahanen.com/app/uploads/2017/09/Fotolia_100501456_Subscription_Monthly_M.jpg') no-repeat;
    background-size: cover
    }

    #form_inputs {
        display: flex;
        justify-content: center;
    
    }

    form {
    padding: 5px;
    margin: 5px;
    box-shadow: 0 2px 5px #f5f5f5; 
    background: #f5f5f5; 
    }
    .fas {
    margin: 25px 10px 0;
    font-size: 72px;
    color: #fff;
    }
    .fa-envelope {
    transform: rotate(-20deg);
    }
    .fa-at , .fa-mail-bulk{
    transform: rotate(10deg);
    }
    input, textarea {
    width: calc(100% - 18px);
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #1c87c9;
    outline: none;
    }
    input::placeholder {
    color: #666;
    }

    #contact_form h1 {
        background: #f5f5f5;
    }
    
    span button {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    background: #1c87c9; 
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    }
    button:hover {
    background: #2371a0;
    }    
    @media (min-width: 568px) {
    .left-part, form {
    width: 50%;
    }
    .fa-envelope {
    margin-top: 0;
    margin-left: 20%;
    }
    .fa-at {
    margin-top: -10%;
    margin-left: 65%;
    }
    .fa-mail-bulk {
    margin-top: 2%;
    margin-left: 28%;
    }
    }